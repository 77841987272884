<script>
import Layout from "@/views/layouts/main.vue";
import appConfig from "@/app.config";
import { mapGetters } from 'vuex';

// import ProcessStat from '@/views/widgets/process-stat/index'
// import LastActivity from "../../widgets/last-activity/index.vue";

export default {
  page: {
    title: "Home",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout
  },
  created() {
    this.init();
  },
  data() {
    return {
      pagetitle: "Home",
      uiPreferencesReady: false
    };
  },
   computed :{
    ...mapGetters('usageProfiles', {
      currentUsageProfile: 'current'
    }),
    ...mapGetters('boot', {
      appReady: 'ready'
    }),
    ...mapGetters('auth', {
      currentUser: 'currentUser',
      userGroupIDs: 'userGroupIDs',
      mapEnabled: 'mapEnabled'
    }),
    ...mapGetters('directories', {
      directories: 'items',
      mapDirectories: 'directoriesOnMap'
    }),
    isMapMenuVisible() {
      return this.mapDirectories.length > 0 && this.mapEnabled && (this.currentUser.uiPreferences.hideMap !== true);
    },
    filteredDirectories() {
      return this.directories.filter(x => x.showOnMenu.find(groupId => this.userGroupIDs.indexOf(groupId) >= 0));
    },
  },
   watch: {
    appReady(newValue, oldValue) {
      if(newValue !== oldValue) {
        this.init();
      }
    }
  },
  methods: {
    init() {
      if(this.appReady) {
        console.log('this.currentUsageProfile', this.currentUsageProfile);
        if(this.currentUsageProfile) {
          if(this.currentUsageProfile.menu.length > 0) {
            for(let i = 0; i < this.currentUsageProfile.menu.length; i++) {
              const mi = this.currentUsageProfile.menu[i];
               if(mi.type === 'Directory') {
                const dir = this.directories.find(dir => dir.id === mi.target);
                if(dir) {
                  this.$router.push('/directories/' + dir.id + '/items');
                  return ;
                }
              } else if(mi.type === 'Map') {
                this.$router.push('/map');
                return ;
              }
            }
          }
        }
        this.uiPreferencesReady = true;
      }
    }
  }
};
</script>


<style scoped lang="scss">
.boot {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9997;
  height: 100%;
  padding: 20px;
  

  .card-container {
    position: absolute;
    left: 20px;
  right: 20px;
  top: 20px;
  bottom: 20px;
    border: 1px solid #eff0f2;
    border-radius: 1rem;
  
  
  }
}

.overlay {
  background-color: rgba(20, 27, 43, 0.55);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  z-index: 9998;
  transition: all 0.2s ease-out;
}
</style>


<template>
  <Layout :pagetitle="pagetitle">
    <div class="alert alert-warning text-center">
      <h5>
      {{$t('common.startPageResolveError')}}</h5>
    </div>
   <div>
    <transition name="fade">
      <div class="boot bg-light" v-if="!uiPreferencesReady">
        <div class="overlay">

        </div>
        <div class="card-container bg-white d-flex align-items-center">
          <div class="box w-100 text-center fs-1">
            <div class="mb-4">
              <img src="@/assets/images/logo-dark.png" alt="" height="36" />
            </div>
            <i class="fa fa-spin fa-spinner "></i> 
          </div>
        </div>
      </div>
    </transition>
  </div>
  </Layout>
</template>
